import React, { useContext } from 'react'
import ApplocationsItems from './SidbarItems/ApplocationsItems'
import DashboardItems from './SidbarItems/DashboardItems'
import MenuItems from './SidbarItems/MenuItems'
import PagesItems from './SidbarItems/PagesItems'
import UIItems from './SidbarItems/UIItems'
import MainContext from '../../Context'

export default function SecondSidebar({ menu }) {

  const { setMainMenu } = useContext(MainContext)
  return (
    <div className='p-4 second-sidebar-container' onClick={() => setMainMenu(0)}>
      {menu === "1" ? (<DashboardItems />
      ) : menu === "2" ? (<PagesItems />
      ) : menu === "3" ? (<ApplocationsItems />
      ) : menu === "4" ? (<UIItems />
      ) : (<MenuItems />)
      }
    </div>
  )
}
