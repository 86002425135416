import React from 'react'
import "./Calender.css"

export default function Calender() {
    return (
        <div className='bg-white py-4 rounded-25p shadow-sm h-500 '>
            <h5 className='h5 px-4 fw-600 '>Calendar</h5>
            <div className='d-flex mb-4 px-4'>
                <span>February 2023</span>
                <button className='btn btn-light bg-gold4 text-white rounded-25p px-3 ms-auto'>Today</button>
                <button className='btn btn-light bg-gold4 text-white rounded-25p px-3 mx-2'>&#10092;</button>
                <button className='btn btn-light bg-gold4 text-white rounded-25p px-3 '>&#10093;</button>
            </div>
            <div className='calender-container'>
            <table className='table text-center fs-14'>
                <thead>
                    <tr className='color-gold4'>
                        <th>Sun</th>
                        <th>Mon</th>
                        <th>Tue</th>
                        <th>Wed</th>
                        <th>Thu</th>
                        <th>Fri</th>
                        <th>Sat</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><span>01</span></td>
                        <td><span>02</span></td>
                        <td><span>03</span></td>
                        <td><span>04</span></td>
                        <td><span>05</span></td>
                        <td><span>06</span></td>
                        <td><span>07</span></td>
                    </tr>
                    <tr>
                        <td><span>08</span></td>
                        <td><span>09</span></td>
                        <td><span className='date-active'>10</span></td>
                        <td><span>11</span></td>
                        <td><span>12</span></td>
                        <td><span>13</span></td>
                        <td><span>14</span></td>
                    </tr>
                    <tr>
                        <td><span>15</span></td>
                        <td><span>16</span></td>
                        <td><span>17</span></td>
                        <td><span>18</span></td>
                        <td><span>19</span></td>
                        <td><span>20</span></td>
                        <td><span>21</span></td>
                    </tr>
                    <tr>
                        <td><span>22</span></td>
                        <td><span>23</span></td>
                        <td><span>24</span></td>
                        <td><span>25</span></td>
                        <td><span>26</span></td>
                        <td><span>27</span></td>
                        <td><span>28</span></td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
    )
}
